/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
// Polyfills
import 'zone.js';

// Typescript emit helpers polyfill
// import 'ts-helpers';

// if ('production' === process.env.ENV) {
//     // Production


// } else {
//     // Development

//     Error.stackTraceLimit = Infinity;

//     require('zone.js/dist/long-stack-trace-zone');

// }
